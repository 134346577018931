/* .menuBlock:hover p {
  color: #3D2309;
}

@media (hover: none) and (max-width: 600px) {
  .menuBlock p {
    color: #3D2309;
  }
} */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500&family=Roboto:wght@100;300;400&display=swap');

@media (max-width: 900px) {
  .menuBlock {
    /* display: none; */
  }
}

.small-asset {
  width: 100%;
  height: auto;
  padding: 10px 0 10px 0;
}

@media (min-width: 600px) {
  /* .align-right, .align-right div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  } */
  .small-asset, .small-asset img {
    width: 70%;
    padding: 0 20px 0 20px;
  }
}

.small-image {
  width: 50%;
  padding: 0 20px 0 20px;
}

.carousel-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}
.carousel-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
}

.swiper-button-next, .swiper-button-prev {
  color: rgb(115, 143, 81) !important;
  /* background: rgba(255, 255, 255, 1) !important; */
  width: 60px !important;
  height: 80px !important;
  margin-top: -40px !important;
}
.swiper-button-prev {
  left: var(--swiper-navigation-sides-offset,-1px) !important;
  right: auto;
}
.swiper-button-next {
  right: var(--swiper-navigation-sides-offset,-2px) !important;
  left: auto;
}

.swiper-slide-prev, .swiper-slide-next {
  opacity: 0.25;
}


.swiper-slide-active {
  opacity: 1;
}

.swiper-custom-pagination {
  text-align: center;
}

.swiper-pagination-bullet-active {
  background: rgb(115, 143, 81) !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 30px !important;
} 

.swiper-container {
  max-width: 100% !important;
}

@media (max-width: 600px) {
  .swiper-slide {
    max-width: 100% !important;
    width: 100% !important; /* needed to fix swiper.js bug for max-width on mobile */
  }
}






/* add mobile menu on lower right corner where it can expand to go to diff sections */