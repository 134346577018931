header a {
  text-decoration: none;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  .header-name {
    font-size: 30px;
    margin: 0;
    color: #92AD70;
    display: flex;
  }
  
  .header-subheading {
    font-size: 16px;
    display: block;
    padding-top: 10px;
    color: #92AD70;
  }
}

.nav-wrap {
  display: flex;
  align-items: center;
  .nav-items {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 36px;
    .nav-item {
      margin: 0 16px;
      :hover, .active {
        font-weight: 500;
      }
    }
  }
  #socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    /* width: auto;
    height: 24px; */
    > a {
      margin: 4px 0 0 0;
    }
  }
}

@media (max-width: 900px) {
  header.open {
    overflow-x: hidden;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: #92AD70;
    .header-container {
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      row-gap: 3em;
    }
    .header-name {
      color: #ffffff;
    }
    #socials a svg {
      /* filter to white */
      /* https://codepen.io/sosuke/pen/Pjoqqp */
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(138deg) brightness(102%) contrast(101%);
    }
  } 
  #menu-container nav button {
    background-color: transparent;
    border: none;
  }
  #menu-container {
    z-index: 100000;
    /* position: fixed; */
    /* bottom: 40px; */
    /* right: 16px; */
    /* background-color: #92AD70; */
    /* box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.15); */
    /* border-radius: 32px; */
    height: 100%;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    overflow: hidden;
    left: 0;
    top: 0;
    flex-direction: column;
    row-gap: 3em;
  }
  #main-menu {
    visibility: hidden;
    display: none;
  }
  #main-menu.open {
    visibility: visible;
    display: flex;
    flex-direction: column;
    row-gap: 4em;
  }
  #menu-container .nav-items {
    flex-direction: column;
    row-gap: 1em;
    margin-right: 0;
    text-align: center;
    .nav-item {
      font-size: 36px;
      width: 100%;
      span {
        color: white;
      }
    }
  }
  #main-menu {
    /* max-width: 100px; */
    /* max-height: 20px; */
    opacity: 0.5;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  #main-menu.open {
    /* max-height: 1200px; */
    opacity: 1;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }

  #hamburgerContainer {
    /* float: right; */
    /* position: relative; */
    padding: 5px;
    position: absolute;
    top: 30px;
    right: 24px;
  }
  #hamburger {
    /* background-color: #92AD70; */
    width: 26px;
    height: 22px;
    /* width: 36px;
    height: 36px; */
    /* left: 14px; */
    /* https://codepen.io/designcouch/pen/ExvwPY */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  #hamburger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    /* color: #ffffff; */
    background: #92AD70;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  #hamburger.open span {
    background-color: #ffffff;
  }
  #hamburger span:nth-child(1) {
    top: 0px;
  }
  #hamburger span:nth-child(2), span:nth-child(3) {
    top: 8px;
  }
  #hamburger span:nth-child(4) {
    top: 16px;
  }
  #hamburger.open span:nth-child(1) {
      top: 8px;
      width: 0%;
      left: 50%;
  }
  #hamburger.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #hamburger.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
}