#projects-fab-container {
  top: auto;
  right: 50%;
  bottom: 20px;
  left: calc(50% - 150px);
  position: fixed;
  z-index: 100000;
}

#projects-fab {
  text-transform: lowercase;
  background-color: #92AD70;
  border-radius: 8px;
  height: 50px;
  min-width: 300px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
}

#projects-fab:hover {
  background-color: #718d4e;
  transition: background-color 200ms ease-in-out;
  -webkit-transition: background-color 200ms ease-in-out;  
  -moz-transition: background-color 200ms ease-in-out;
}

#projects-fab:visited {
  color: white;
}