.circle-mask {
  aspect-ratio: 1;
  img {
    border-radius: 50%;
    object-fit: cover;
  }
}

.resume {
  .resume-section {
    margin-bottom: 24px;
  }
  .resume-subsection {
    margin-bottom: 16px;
  }
  h1 {
    margin: 0;
    line-height: 1.5em;
  }
  p {
    font-size: 16px;
    margin: 0;
    line-height: 1.5em;
  }
  ul {
    padding-inline-start: 0;
    margin-block-start: 0.5em;
  }
  .heading {
    font-weight: 400;
  }
  .subheading {
    font-weight: 400;
    color: gray;
  }
  .resume-button {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
}