.h2 {
  font-size: 56px;
  color: #3D2309;
  font-family: 'Roboto Mono', monospace;
  margin-block-start: 0;
  margin-block-end: 0;
}

.subheading {
  color: #3D2309;
}

.rightalign {
  text-align: right;
}

.polaroid {
  -webkit-transition:  -webkit-transform .2s ease-out;
  -moz-transition: -moz-transform .2s ease-out;
  -o-transition: -o-transform .2s ease-out;
  -ms-transition: -ms-transform .2s ease-out; 
  transition: transform .2s ease-out; 
  /* transform: rotate(0) translate(0, 0); */
  &:hover {
    /* transform: rotate(4deg) translate(0, 3px);
    transition-duration: .2s; */

    -webkit-transform:scale(1.1);
    -moz-transform:scale(1.1);
    -o-transform:scale(1.1);
    transform:scale(1.1);
  }
}

.stickyTitle {
  top: 48px;
  position: sticky;
  position: -webkit-sticky;
}