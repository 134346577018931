.button-secondary {
  background-color: #FFFFFF;
  border-radius: 8px; 
  border:#92AD70 1px solid;
  /* border: none; */
  color: #92AD70 !important;
  text-align: center;
  /* text-decoration: none; */
  font-size: 20px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-decoration: underline solid transparent; */
}

.button-secondary:hover {
  cursor: pointer;
  /* background-color: #f6fcef; */
  /* text-decoration: underline; */
  color: #718d4e !important;
  box-shadow: 0px 0px 0px 1px #718d4e inset;
  transition: 100ms ease-in-out;
  -webkit-transition: 100ms ease-in-out;  
  -moz-transition: 100ms ease-in-out;
  -o-transition: 100ms ease-in-out;
}

.buttonWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

