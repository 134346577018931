.videoContainer {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pitchContent {
  margin-left: auto;
  margin-right: auto;
}

.subheading {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: -8px;
  margin-top: 24px;
}

p {
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
}