#contact-me {
  color: #3D2309;
  padding-top: 100px;
  padding-bottom: 20px;
  /* background-color: #e2c9b0; */
  /* background-color: white; */
  /* background-color: #D2B597; */
  p {
    font-size: 16px;
  }
}

.footer-text {
  margin-top: 20px;
  color: #3D2309;
  /* color: #ffffff; */
}