.button-primary {
  background-color: #92AD70;
  border-radius: 8px;
  border: none;
  color: white !important;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  width: 250px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-primary:hover {
  cursor: pointer;
  background-color: #718d4e;
  /* color: #92AD70 !important; */
  /* border:#92AD70 1px solid; */
  /* background-color: transparent; */
  transition: 200ms ease-in-out;
  -webkit-transition: 200ms ease-in-out;  
  -moz-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
}

.buttonWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

