@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500&family=Roboto:wght@100;300;400&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0px;
}

.container {
  width: 100%;
}


h1 {
  font-size: 36px;
}

h2 {
  font-size: 18px;
}

p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  &.project-description {
    font-size: 14px;
    line-height: 20px;
  }
}

code {
  background-color: #eeeeee;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  padding: 1px 5px;
  border-radius: 5px;
}

@media screen and (min-width: 600px) {
	h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 20px;
    line-height: 32px;
    &.project-description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
@media screen and (min-width: 900px) {
	h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) {
	h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 20px;
  }
}

.project-section {
  scroll-margin-block-start: 100px;
}

.highlight {
  background:linear-gradient(180deg, transparent 50%, rgba(146, 173, 112, 0.5) 50%);
  /* transition: background-position 275ms ease; */
  text-decoration: none;
}

/* a:hover {
  background:linear-gradient(180deg, transparent 60%, #85c285 30%);
  animation-duration: 2s;
} */

@keyframes link {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

::selection {
  color: white;
  background: #637b46;
}

.heading-text {
  color: #3D2309;
}

.body-text-color {
  color: #1a1a1a;
}

.monospace-100 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 100;
  color: #3D2309;
}

.monospace-200 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  color: #3D2309;
}

.monospace-300 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  color: #3D2309;
}

.monospace-400 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  color: #3D2309;
}

.monospace-500 {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  color: #3D2309;
}

.section-title {
  font-weight: 400;
  color: #3D2309;
}

.project-title {
  font-size: 48px;
}

.project-subheading {
  
}

a {
  color: #3D2309;
  text-decoration: none;
}
a:visited {
  color: #3D2309;
}

.content-link, .content-link:visited {
  color: #637b46;
  font-weight: 500;
  text-decoration: underline;
}

blockquote {
  border-left: 3px solid #92AD70;
  margin: 24px 10px;
  padding: 0px 12px;
  color: #637b46;
  font-size: 36px;
}

.caption-container {
  text-align: center;
  display: flex;
  justify-content: center;
}

caption {
  text-align: center;
  display: block;
  font-weight: 300;
  font-size: 14px;
  margin-top: 10px;
  width: 80%;
  color: #747474;
  margin-top: 0px;
}

b {
  font-weight: 600;
}

li {
  margin-bottom: 9px;
}

.project-content p, .project-content h2, .project-content h1, blockquote {
  /* max-width: 680px; */
  margin-left: auto;
  margin-right: auto;
  &.project-intro {
    /* max-width: none; */
  }
}

.__react_modal_image__header {
  background-color: transparent !important;
}

.__react_modal_image__caption {
  color: transparent;
}

.__react_modal_image__icon_menu {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.__react_modal_image__modal_container {
  background-color: rgba(254, 254, 254, 0.75) !important;
}


.__react_modal_image__modal_content img, .__react_modal_image__modal_content svg {
  /* margin-top: 20px; */
  /* width: 90%; */
  /* width: 100%; */
  
  max-width: 100%;
  object-fit:contain;
  /* overflow:scroll !important; */
  height: 85%;
}

.drop-shadow {
  filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.08));
}

.drop-shadow-hover:hover {
  filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.4));
}

.drop-shadow-hover-light:hover {
  filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.08));
  cursor: zoom-in !important;
}

.text-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #92AD70; */
  /* gap: 16px; */
  padding: 24px 0;
  .leaf-divider {
    width: 20%;
    @media screen and (max-width: 900px) {
      width: 50%;
    }
  }
  /* .small-plant {
    width: 12px;
  } */
}

.return-home-link {
  display: inline-block;
}
.return-home-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-weight: 300;
  font-size: 15px;
  margin-left: -6px;
}
.return-home-container:hover {
  text-decoration: underline;
  font-weight: 400;
}
.return-home-container svg {
  margin: 0;
}


/* .MuiContainer-maxWidthXl, .MuiContainer-maxWidthLg {
  padding-left: 50px !important;
  padding-right: 50px !important;
  
} */


@media screen and (min-width: 900px) {
  .MuiContainer-maxWidthXl, .MuiContainer-maxWidthLg {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

/* sticky footer */
/* .Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.SiteContent {
  flex: 1;
} */