h1 {
  margin-bottom: 10px;
  font-size: 33px;
}

.typewriter-block {
  /* height: 500px; */
}

@media screen and (min-width: 900px) {
  .spacer {
    height: 24px;
  }
  /* .tonys-polaroid {
    margin-top: -96px !important;
  } */
}

.about-button a {
  width: 200px;
  margin-right: 15px;
}

@media screen and (max-width: 900px) {
  .about-button a {
    width: 155px;
  }
}

@media screen and (max-width: 375px) {
  .about-button .buttonWrapper {
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

