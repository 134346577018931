
@media (min-width: 901px) {
  .MenuItem p {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 24px;
  }
  #back-top-link {
    text-decoration: none;
    font-weight: 300;
  }
  #back-top-link p {
    margin: 0;
  }
  #back-top-link p .link {
    background: none;
  }
  #back-top-link:hover {
    text-decoration: underline;
    font-weight: 400;
    transition: 200ms ease-in-out;
    -webkit-transition: 200ms ease-in-out;  
    -moz-transition: 200ms ease-in-out;
    -o-transition: 200ms ease-in-out;
  }

  .MenuItem span {
    padding-bottom: 3px;
  }
  .link {
    /* display: inline-block; */
    background:linear-gradient(180deg, transparent 50%, rgba(146, 173, 112, 0.5) 50%);
    background: linear-gradient(180deg, rgba(146, 173, 112, 0.5), rgba(146, 173, 112, 0.5));
    background-position: 0 100%;
    background-size: 0% 50%;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; 
    -webkit-transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
    -moz-transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
    -o-transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
  }
  
  .link:hover {
    font-weight: 500;
    background-position: 100% 100%;
    background-size: 100% 50%;
  }
  
  .activeItem {
    background:linear-gradient(180deg, transparent 50%, rgba(146, 173, 112, 0.5) 50%);
    background: linear-gradient(180deg, rgba(146, 173, 112, 0.5), rgba(146, 173, 112, 0.5));
    background-position: 100% 100%;
    background-size: 100% 50%;
    background-repeat: no-repeat;
    font-weight: 500;
    transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
    -webkit-transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
    -moz-transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
    -o-transition: 
      background-size 0.3s,
      background-position 0s 0.3s;
  }
}

@media (max-width: 900px) {
  .link {
    color: #ffffff;
    padding: 10px;
    display: block;
  }
  .MenuItem a p {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    font-size: 20px;
  }
  .activeItemContainer, #MenuItem p:active {
    background-color: #ffffff;
    /* padding: 10px; */
    border-radius: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    /* padding: 10px; */
    .link {
      color: #3D2309;
    }
  }

}