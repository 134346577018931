.tagContainer {
  border-radius: 10px;
  /* height: 12px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  padding: 4px 8px 4px 8px;
  /* &.mobile {
    background-color: #92AD70;
  } */
  &.device {
    background-color: #fff6b1;
  }
  &.role {
    background-color: #d1f1f8;
  }
  &.context {
    background-color: #eaddfc;
  }
  /* &.engineering {
    background-color: #3D2309;
  } */
  .tagName {
    color: #3D2309;
    font-size: 10px;
    letter-spacing: 0.1em;
  }
}