.polaroid {
  margin-top: 30px;
  margin-bottom: 30px;
  display: inline-block;
  background: #ffffff;
  padding: 25px;
  text-align: center;
  filter: drop-shadow(1px 4px 6px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  box-sizing: border-box;
  transition: all .2s;
  width: 100%;
}

.polaroid.wide:hover {
  /* transform: rotate(10deg); */
  transform: rotate(-2deg) translate(0, -3px);
  filter: drop-shadow(1px 4px 6px rgba(0, 0, 0, 0.15));
  transition-duration: .2s;
  /* transition: all .7s ease-in-out; */
}

@keyframes tilt {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(10deg);
  }
}

/* .polaroid:hover a img {
  opacity: 70%;
  transition: opacity 200ms ease-in-out;
  -webkit-transition: opacity 200ms ease-in-out;  
  -moz-transition: opacity 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out;
}

.polaroid:hover .aspectRatioWide + .polaroidText p {
  color: #806549;
  transition: color 200ms ease-in-out;
  -webkit-transition: color 200ms ease-in-out;  
  -moz-transition: color 200ms ease-in-out;
  -o-transition: color 200ms ease-in-out;
} */

/* washy tape */
.polaroid:before {
  width: 50%;
  height: 30px;
  display: block;
  content: "";
  background: rgba(146, 173, 112, 0.5);
  transform: translateX(50%) translateY(calc(-50% - 25px));
}


.polaroid a {
  text-decoration: none;
  color: #3D2309;
}

img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.aspectRatioRegular {
  aspect-ratio: 1;
}

.aspectRatioWide {
  /* aspect-ratio: 1.8/1; */
}

p.description {
  font-size: 16px;
}

.tagsContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.polaroid.regular .polaroidText p {
  margin-bottom: 0;
}